<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작업종류 -->
          <c-select
            :comboItems="workTypeItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopWorkTypeCd"
            label="작업종류"
            v-model="searchParam.sopWorkTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안전조치사항 목록"
      tableId="workCheckItem"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable"
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveWorkCheckItem"
            @btnCallback="saveWorkCheckItemCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-check-item',
  data() {
    return {
      searchParam: {
        plantCd: null,
        sopWorkTypeCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [],
        data: [],
      },
      workTypeItems: [], // 변경종류
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workChekItem.list.url
      this.saveUrl = transactionConfig.sop.swp.workChekItem.save.url
      // code setting
      this.$comm.getComboItems('SOP_WORK_TYPE_CD').then(_result => {
        this.workTypeItems = _result
        this.$comm.getComboItems('SOP_CHECK_ITEM_KIND_CD').then(__result => {
          this.grid.columns = [
            {
              name: 'plantCd',
              field: 'plantCd',
              label: '사업장',
              align: 'center',
              sortable: true,
              required: true,
              style: 'width:100px',
              type: 'plant',
            },
            {
              name: 'sopWorkTypeCd',
              field: 'sopWorkTypeCd',
              label: '작업종류',
              align: 'center',
              sortable: true,
              required: true,
              style: 'width:100px',
              type: 'select',
              itemText: 'codeName',
              itemValue: 'code',
              comboItems: _result,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '항목명',
              align: 'left',
              sortable: true,
              required: true,
              style: 'width:300px',
              type: 'text',
            },
            {
              name: 'sopCheckItemKindCd',
              field: 'sopCheckItemKindCd',
              label: '항목입력 타입',
              align: 'center',
              sortable: true,
              required: true,
              style: 'width:100px',
              type: 'select',
              itemText: 'codeName',
              itemValue: 'code',
              comboItems: __result,
            },
            {
              name: 'useFlag',
              field: 'useFlag',
              label: '사용여부',
              align: 'center',
              sortable: true,
              style: 'width:100px',
              type: 'check',
              true: 'Y',
              false: 'N',
            },
            {
              name: 'sortOrder',
              field: 'sortOrder',
              label: '순번',
              align: 'center',
              style: 'width:100px',
              sortable: true,
              type: 'number',
            },
          ]
        });
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopCheckItemId: String(date.getTime()),  // 점검항목 일련번호
        plantCd: null,  // 사업장 코드
        sopWorkTypeCd: null,  // 일반/화기 + 보충작업
        checkItemName: '',  // 점검항목명
        sopCheckItemKindCd: null,  // 체크항목 종류
        useFlag: 'Y',  // 사용여부
        sortOrder: 0,  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveWorkCheckItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveWorkCheckItemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
